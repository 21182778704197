import React, { useEffect, useState, useRef } from 'react';
import { useForm, Controller } from "react-hook-form";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, Button } from 'react-bootstrap';
import Datepicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment';
import axios from 'axios';
import { render } from '@testing-library/react';
import { env } from './const';
// import { headers } from '../utils/common';
// import { Role } from '../utils/role';
// import { getRole } from '../utils/common';
import DataTable from "react-data-table-component";
import { Tabs, Tab } from 'react-bootstrap-tabs';
const RejectedGoods = (props) => {
    const columns = [
        {
            name: "Site",
            selector: "site",
            sortable: true,
            minWidth: '120px'
        },
        {
            name: "Block",
            selector: "block",
            sortable: true,
            minWidth: '50px'
        },
        {
            name: "Contractor",
            selector: "contractorName",
            sortable: true,
            minWidth: '140px'
        },
        {
            name: "Material ID",
            selector: "materialName",
            sortable: true,
            minWidth: '100px'
        },
        {
            name: "Material Name",
            selector: "materialID",
            sortable: true,
            minWidth: '300px'
        },
        {
            name: "QTY",
            selector: "quantity",
            sortable: true,
            minWidth: '50px',
            cell: row => (
                <span className='textHiglighter'>{row.quantity}</span>
            )
        },
        {
            name: "UOM",
            selector: "uom",
            sortable: true,
            maxWidth: '50px',

        },
        {
            name: "Date",
            selector: "modifiedDate",
            sortable: true,
            minWidth: '120px',
            cell: row => (
                moment(row.modifiedDate).format('DD-MMM-YYYY')
            )
        }
    ];
    const { register, handleSubmit, watch, formState: { errors } } = useForm({
        mode: 'onChange'
    });


    const [show, setShow] = useState(false);
    const [showResults, setShowResults] = useState(false)
    const [resultMessage, setResultMessage] = useState({});
    const [data, setData] = useState([]);
    const rejectedList = () => {
        axios.get(env.produrl + '/goodsissue/list/rejected')
            .then(res => {
                setData(res.data.list);
            }).catch((err) => {
                console.log(err)
            });
    }

    useEffect(() => {
        rejectedList();
    }, [])


    return (
        <div className="pending">

            <div className='row'>
                <div className='col-md-12'>
                    <DataTable
                        columns={columns}
                        data={data}
                        defaultSortField="batchID"
                        pagination
                        //selectableRows
                        // selectableRowsComponent={BootyCheckbox}
                        // onSelectedRowsChange = {handleChange}
                        dense
                        selectableRowsHighlight='true'
                        compact
                        highlightOnHover='true'
                        striped
                    />
                </div>
            </div>
        </div>
    );
}

export default RejectedGoods;