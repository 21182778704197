import React, { useState } from 'react';
import ReactEcharts from 'echarts-for-react';
import { useLoading, Bars, ThreeDots } from '@agney/react-loading';
const ProductionSchedules = (props) => {
    const { containerProps, indicatorEl } = useLoading({
        loading: true,
        indicator: <Bars width="22" color="#333" />,
    })
    const [showLoader, setShowLoader] = useState(false);
    const changeHandler = (event) => {
        //Update Graph Parameters
    }
    // const [sites, setsites] = useState(props.prodschdlBarChart);
    // const sitesData = props.prodschdlBarChartData.data;
    // const sitesData = [
    //     {
    //         name: 'SITE001',
    //         data: [2, 1, 5, 6, 8, 3, 2, 7, 9, 12, 3, 4, 1, 5,3],
    //         workSchedulesAtRisk: [2, 3, 1, 4, 5, 3, 2, 5, 3, 2],
    //         materialsAtRisk: [1, 2, 1, 3, 2, 2, 1,2, 2, 1,2, 2]
    //     },
    //     {
    //         name: 'SITE002',
    //         data: [1, 2, 4, 5, 7, 2, 1, 6, 10, 11, 11, 5, 1, 5],
    //         workSchedulesAtRisk: [2, 3, 1, 4, 5, 3, 2, 3, 1, 4],
    //         materialsAtRisk: [1, 2, 1, 3, 2, 2, 1,2, 2, 1,2, 2]
    //     },
    //     {
    //         name: 'SITE003',
    //         data: [3, 1, 3, 6, 9, 12, 11, 6, 10, 11, 11, 5, 1, 5],
    //         workSchedulesAtRisk: [2, 3, 1, 4, 5, 3, 2, 5, 3, 2, 3],
    //         materialsAtRisk: [1, 2, 1, 3, 2, 2, 1,2, 2, 1,2, 2, 1]
    //     },
    //     {
    //         name: 'SITE004',
    //         data: [1, 4, 7, 10, 9, 12, 11, 6, 12, 7, 9, 8, 6, 9],
    //         workSchedulesAtRisk: [2, 3, 1, 4, 5, 3, 2, 5, 3, 2],
    //         materialsAtRisk: [1, 2, 1, 3, 2, 2, 1,2, 2, 1,2, 2, 1]
    //     },
    //     // Add more sites as needed
    // ];

    // Define months for the x-axis
    const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']

    // const sites = {
    //     data: [
    //         {
    //             name: 'New York',
    //             workSchedulesAtRisk: [2, 3, 1, 4, 5, 3, 2, 5, 3, 2, 5, 3],
    //             materialsAtRisk: [1, 2, 1, 3, 2, 2, 1, 2, 2, 1, 2, 2, 2, 1]
    //         },
    //         {
    //             name: 'San Francisco',
    //             workSchedulesAtRisk: [2, 3, 1, 4, 5, 3, 2, 3, 1, 4, 2, 1,],
    //             materialsAtRisk: [1, 2, 1, 3, 2, 2, 1, 2, 2, 1, 2, 2, 2, 1]
    //         },
    //         {
    //             name: 'Seattle',
    //             workSchedulesAtRisk: [2, 3, 1, 4, 5, 3, 2, 5, 3, 2, 3, 2, 1,],
    //             materialsAtRisk: [1, 2, 1, 3, 2, 2, 1, 2, 2, 1, 2, 2, 1, 2, 1,]
    //         },
    //         {
    //             name: 'Dallas',
    //             workSchedulesAtRisk: [2, 3, 1, 4, 5, 3, 2, 5, 3, 2, 2, 1, 1],
    //             materialsAtRisk: [1, 2, 1, 3, 2, 2, 1, 2, 2, 1, 2, 2, 1, 2, 1]
    //         }
    //     ],
    //     months: ['JAN 23', 'FEB 23', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']

    // }

    const sites = props.prodschdlBarChart;

    let options = {

        tooltip: {
            trigger: 'item',
            axisPointer: {
                type: 'shadow'
            },
            formatter: function (item) {
                // Finding the site's additional data based on the seriesName of the hovered item
                const siteData = sites.data.find(site => site.name === item.seriesName);
                if (!siteData) return ''; // In case no data is found for the site, return an empty string

                // Accessing the specific data for the hovered site
                const workSchedulesAtRisk = siteData.workSchedulesAtRisk[item.dataIndex];
                const materialsAtRisk = siteData.materialsAtRisk[item.dataIndex];

                // Constructing the tooltip content
                let tooltipContent = `<div><strong>${item.marker} ${item.seriesName} - ${item.name}</strong></div>
                                      <div>Work Orders at risk: <strong>${workSchedulesAtRisk}</strong></div>
                                      <div>Materials at risk: <strong>${materialsAtRisk}</strong></div>`;


                return tooltipContent;
            }
        },
        legend: {
            data: sites.data.map(item => item.name),
            orient: 'horizontal', // This is optional as 'horizontal' is the default value
            top: '0%',
            right: '0%'
        },
        grid: {

            left: 40,
            top: 30,
            right: 35,
            bottom: 30,
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                data: sites.months,
                axisTick: {
                    alignWithLabel: true
                },
                name: 'Months',
                nameLocation: 'middle',
                nameGap: 30
            }
        ],
        yAxis: [
            {
                type: 'value',
                name: 'Work Schedules at Site',
                nameLocation: 'middle',
                nameGap: 50,

            }
        ],
        series: sites.data.map(site => ({
            name: site.name,
            type: 'bar',
            barWidth: '15%',
            stack: 'total',
            label: {
                show: false
            },
            emphasis: {
                focus: 'series'
            },
            data: site.workSchedulesAtRisk
        })),

    };

    return (
        <div className="Temp">
            {/* {props.data.xAxis.length>0 ? */}
            <div className='row' style={{ marginBottom: 15 }}>
                <div className='col-md-6'>
                    <div class="form-inline">
                        <h2 class="m-0 text-dark" style={{ display: 'inline' }}>Work Order at Risks<small style={{ textTransform: 'lowercase' }}> (due to supplier production delays)</small> &nbsp; &nbsp;
                            {showLoader ?
                                <section {...containerProps} style={{ "margin-top": "0px", display: 'inline' }}>
                                    {indicatorEl} {/* renders only while loading */}
                                </section> : ""
                            }
                        </h2>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div class="row justify-content-end">
                        <div class="col-auto">
                            <div class="form-inline">
                                <label for="exampleSelect" class="mr-2">Filter:</label>
                                <select disabled onChange={changeHandler} class="form-control form-control-sm" id="exampleSelect">
                                    <option value={12}>Next 12 Months (default) </option>
                                    <option value={6}>Next 6 Months</option>
                                    <option value={18}>Next 18 Months</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ReactEcharts
                option={options}
                style={{ height: "300px", width: "100%" }}
                opts={{ renderer: "svg" }}
            />
        </div>
    );
}
export default ProductionSchedules;