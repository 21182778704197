import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import QaBar from '../dashboard/qaBarhart';
import { IoMdArrowRoundBack } from "react-icons/io";
import { useHistory } from "react-router-dom";
const ImpactedWorks = ({ showPopup, taskID, usersData, closePopup }) => {
    const columns = [
        {
            name: 'MATERIAL ID',
            selector: "productID",
            minWidth: '150px',
            sortable: true
        },
        {
            name: 'QTY',
            selector: "qty",
            minWidth: '80px',
            sortable: true
        },
        {
            name: 'UOM',
            selector: "uom",
            minWidth: '80px',
            sortable: true
        },
        {
            name: 'PLANNED INSTALLATION DATE',
            selector: "plannedInstallationDate",
            minWidth: '250px',
            sortable: true
        },
        {
            name: 'SUPPLIER',
            selector: "supplier",
            minWidth: '150px',
            sortable: true
        },
        {
            name: 'CURRENT STATUS',
            selector: "currentStatus",
            minWidth: '200px',
            sortable: true
        },
        {
            name: 'EXPECTED DEVIATION',
            selector: "expectedDeviation",
            minWidth: '200px',
            sortable: true
        }
    ];

    const data = [
        {
            productID: "IT001",
            qty: "20",
            uom: "EA",
            plannedInstallationDate: "23-11-2023",
            supplier: "SUP001",
            currentStatus: "Yet to Install by Supplier",
            expectedDeviation: '5 Days'
        },
        {
            productID: "IT002",
            qty: "24",
            uom: "EA",
            plannedInstallationDate: "26-11-2023",
            supplier: "SUP001",
            currentStatus: "Yet to Deliver by Site",
            expectedDeviation: '5 Days'
        },
        {
            productID: "IT003",
            qty: "10",
            uom: "EA",
            plannedInstallationDate: "27-11-2023",
            supplier: "SUP003",
            currentStatus: "Delay in Logistics",
            expectedDeviation: '8 Days'
        },


    ]

    const items = [
        { id: 'WRK0001', color: 'red' },
        { id: 'WRK0002', color: 'red' },
        { id: 'WRK0003', color: 'green' },
        { id: 'WRK0004', color: 'green' },
        { id: 'WRK0005', color: 'green' },
        { id: 'WRK0006', color: 'orange' },
        { id: 'WRK0007', color: 'orange' },
        { id: 'WRK0009', color: 'red' },
        { id: 'WRK0010', color: 'red' },
        // Add the rest of your items here
    ];
    const [activeIndex, setActiveIndex] = useState(null);
    const history = useHistory();
    const handleItemClick = (index) => {
        setActiveIndex(index);
    };
    const handleBackClick = () => {
        history.push('/dashboard', { propActiveState: 'qaInstallation' });
    };

    return (
        <div className="farmMaster">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h2 class="m-0 text-dark">Impacted Works</h2>
                </div>
                <div class="col-sm-6" >
                    <span style={{ display: "flex", justifyContent: "end" }}>
                        <div className="btnBack" onClick={handleBackClick}>
                            <IoMdArrowRoundBack size={20}></IoMdArrowRoundBack>&nbsp; Back
                        </div>
                    </span>
                </div>
            </div>
            <div className="card">
                <div class="card-body">

                    <div className='row'>

                        <div className='col-md-2 sideBorder'>
                            <div class="input-group">
                                <input type="search" className="form-control form-control-sm"
                                    placeholder="Search Work ID"
                                />
                                <div class="input-group-append">
                                    <button type="submit" className="btn btn-sm btn-default">
                                        <i class="fa fa-search"></i>
                                    </button>
                                </div>
                            </div>
                            <ul className='sideNav' style={{minHeight:'600px'}}>
                                {items.map((item, index) => (
                                    <li
                                        key={item.id}
                                        className={index === activeIndex ? 'workItemActive' : ''}
                                        onClick={() => handleItemClick(index)}
                                    >
                                        {item.id} <span className={`circle ${item.color}Indicator`}></span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className='col-md-10'>
                            <div className='workHeader'>

                                <div className='row'>

                                    <div className='col-md-4'>
                                        <h2><strong>Work ID:</strong> {'WRK0001'}</h2>
                                    </div>
                                    <div className='col-md-4'>
                                        <strong>Site ID: </strong> SITE001
                                        &nbsp; <strong>Contractor: </strong> CTR001
                                    </div>
                                    <div className='col-md-3'>
                                        <div className='status'>
                                            <strong>Status: </strong> <span>At Risk</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='workSummary row'>

                                <div className='col-md-3'>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <div className='summaryHeader'>Work Type:</div>
                                        </div>
                                        <div className='col-md-12'>
                                            <span>SITE0001, Minnesota</span>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-3'>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <div className='summaryHeader'>Description:</div>
                                        </div>
                                        <div className='col-md-12'>
                                            <span>Concrete works</span>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-3'>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <div className='summaryHeader'>Start Date:</div>
                                        </div>
                                        <div className='col-md-12'>
                                            <span>20-FEB-2024</span>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-3'>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <div className='summaryHeader'>End Date:</div>
                                        </div>
                                        <div className='col-md-12'>
                                            <span>25-FEB-2024</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='taskView' style={{marginTop:'30px'}}>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <QaBar />
                                    </div>
                                </div>
                            </div>
                            <div className='taskView' >
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <h2>Materials Linked To Work</h2>
                                        <DataTable
                                            columns={columns}
                                            data={data}
                                            defaultSortField="taskID"
                                            pagination
                                            dense
                                            selectableRowsHighlight='true'
                                            compact
                                            highlightOnHover='true'
                                            striped
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* <SkillView key={Math.random()}
                            showPopup={showSkillViewPopup}
                            taskID={"TSK001"}
                            closePopup={() => { setShowSkillViewPopup(false) }}
                        ></SkillView>
                        <MaterialInfo key={Math.random()}
                            showPopup={showMaterialInfoPopup}
                            taskID={"TSK001"}
                            closePopup={() => { setShowMaterialInfoPopup(false) }}
                        ></MaterialInfo> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ImpactedWorks;