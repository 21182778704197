import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { env } from './const';
import USAChoroplethMap from '../dashboard/usaMap';
import InfoBox from "../dashboard/infoBox";
import { FiFileText, FiAlertOctagon, FiLogIn, FiLogOut, FiMapPin, m, FiTruck } from "react-icons/fi";
import { RiFileDamageLine } from "react-icons/ri";
import { GiHealthIncrease } from "react-icons/gi";
import { MdOutlineSettings, MdLocalShipping, MdOutlinePianoOff } from "react-icons/md";
import { VscLibrary } from "react-icons/vsc";
import { GrMap } from "react-icons/gr";
import { AiOutlineGold } from "react-icons/ai";
import { AiOutlineGlobal } from "react-icons/ai";
import { AiOutlineDoubleLeft } from "react-icons/ai";
import { FiFilter } from "react-icons/fi";
import DataTable from "react-data-table-component";
import InventoryChart from "../dashboard/inventoryLineChart";
import { IoEyeOutline } from "react-icons/io5";
import { useHistory, useLocation } from 'react-router-dom';
import ProductForecast from "../dashboard/productForecast";
import ProductionSchedules from "../dashboard/productionSchedules";
import LogisticsRisks from "../dashboard/logisticsRisks";
import Datepicker from 'react-datepicker';
import { useLoading, Bars, ThreeDots } from '@agney/react-loading';
import USACQCMap from "../dashboard/usaMapQCRisk";
// import PlanningLedger from "../calendar/planningLedger";
import { getSiteWiseWorkIdCount, siteWiseProductionCount, geProdschdlSiteWiseRisksMap, getSoftDemandSiteWiseRisksMap, getprodschdlBar, getSiteWiseRisksBar } from "../pages/const"
import { map } from "jquery";
const Dashboard = () => {
  const location = useLocation();
  const [partyType, setPartyType] = useState(sessionStorage.getItem('partyType'));

  const [activeBox, setActiveBox] = useState('softDemand');
  const history = useHistory();
  const { propActiveState } = location.state || {};

  const [prodschdlCount, setProdschdlCount] = useState('');
  const [softDemandCount, setSoftDemandCount] = useState('');


  const [prodschdlMapData, setProdschdlMapData] = useState([]);
  const [softDemandMapData, setSoftDemandMapData] = useState([]);

  const [prodschdlBarchartData, setProdschdlBarchartData] = useState([]);
  const [softDemandBarChartData, setSoftDemandBarChartData] = useState([]);

  const [showProdRisk, setShowProdRisk] = useState(false)

  // const [softDemandMapData, setSoftDemandMapData] = useState([]);

  const { containerProps, indicatorEl } = useLoading({
    loading: true,
    indicator: <Bars width="22" color="#333" />,
  });
  const [showLoader, setShowLoader] = useState(false);

  // Function to handle box click
  const handleBoxClick = (boxName) => {
    // alert(boxName)
    setActiveBox(boxName);
    mapData(boxName);
    if (boxName === 'productionRisks') {
      prodschdlBarChartAPI()
    }
  };

  const InventoryPlannedvsShortage = () => {
    // Navigate to the desired screen upon click
    history.push('/PlannedVsShortage');
  };

  const Damage = () => {
    history.push('/Damage')
  }
  const OutofStockInventory = () => {
    history.push('/OutofStockInventory')
  }
  const ApproachingOutofStock = () => {
    history.push('/ApproachingOutofStock')
  }
  const SiteWiseDeviations = () => {
    history.push('/DeviationDays')
  }

  const [approachingStockData, setApproachingStockData] = useState([
    {
      site: "SITE001",
      location: "New York",
      pid: "PID001",
      qty: "30",
      uom: "EA",
      approachingDate: "10-Mar-2024"
    },
    {
      site: "SITE001",
      location: "New York",
      pid: "PID055",
      qty: "450",
      uom: "EA",
      approachingDate: "12-Mar-2024"
    },
    {
      site: "SITE001",
      location: "New York",
      pid: "PID567",
      qty: "23",
      uom: "EA",
      approachingDate: "15-Mar-2024"
    },
    {
      site: "SITE001",
      location: "New York",
      pid: "PID896",
      qty: "54",
      uom: "EA",
      approachingDate: "25-Mar-2024"
    },
    {
      site: "SITE001",
      location: "New York",
      pid: "PID056",
      qty: "54",
      uom: "EA",
      approachingDate: "28-Mar-2024"
    }
  ])
  const [requiredStock, setRequiredStock] = useState([
    {
      site: "SITE001",
      location: "New York",
      pid: "PID004",
      qty: "13",
      uom: "EA",
      date: "10-Mar-2024"
    },
    {
      site: "SITE001",
      location: "New York",
      pid: "PID051",
      qty: "453",
      uom: "EA",
      date: "12-Mar-2024"
    },
    {
      site: "SITE001",
      location: "New York",
      pid: "PID122",
      qty: "29",
      uom: "EA",
      date: "15-Mar-2024"
    },
    {
      site: "SITE001",
      location: "New York",
      pid: "PID213",
      qty: "67",
      uom: "EA",
      date: "25-Mar-2024"
    },
    {
      site: "SITE001",
      location: "New York",
      pid: "PID042",
      qty: "43",
      uom: "EA",
      date: "28-Mar-2024"
    }
  ])
  const appriachingInvColumns = [
    {
      name: "Site ID",
      selector: "site",
      sortable: true,
    },
    {
      name: "Location",
      selector: "location",
      sortable: true,
      minWidth: '130px',
    },
    {
      name: "Material",
      selector: "pid",
      sortable: true,
      minWidth: '120px',
    },
    {
      name: "QTY",
      selector: "qty",
      sortable: true,

      cell: row => (
        <span>{row.qty} {row.uom}
        </span>
      )
    },
    {
      name: "Date",
      selector: "approachingDate",
      sortable: true,
      minWidth: '120px',
      cell: row => (
        <span className='redcolor'>{row.approachingDate}
        </span>
      )
    }
  ];
  const requiredStockColumns = [
    {
      name: "Site ID",
      selector: "site",
      sortable: true,
    },
    {
      name: "Location",
      selector: "location",
      sortable: true,
      minWidth: '130px',
    },
    {
      name: "Material",
      selector: "pid",
      sortable: true,
      minWidth: '120px',
    },
    {
      name: "QTY",
      selector: "qty",
      sortable: true,

      cell: row => (
        <span className='redcolor'>{row.qty} {row.uom}
        </span>
      )
    },
    {
      name: "Date",
      selector: "date",
      sortable: true,
      minWidth: '120px',
      cell: row => (
        <span>{row.date}
        </span>
      )
    }
  ];
  const [selectedValue, setSelectedValue] = useState("flexRadioDefault2"); // Default checked value

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  useEffect(() => {
    // console.log("propActiveState", propActiveState, activeBox);
    if (propActiveState !== undefined) {
      setActiveBox(propActiveState)
    }
    softDemandCountAPI();
    prodschdlCountAPI();
    mapData('softDemand');
    softDemandBarChartAPI();
    if (propActiveState === 'productionRisks') {
      prodschdlBarChartAPI();
      mapData('productionRisks');
    }
  }, []);

  const softDemandCountAPI = () => {
    setShowLoader(true);
    getSiteWiseWorkIdCount().then(res => {
      setShowLoader(false);
      if (res.status === 200) {
        setSoftDemandCount(res.data.data);
      }
    }).catch((err) => {
      console.log(err)
    });
  };
  const prodschdlCountAPI = () => {
    setShowLoader(true);
    siteWiseProductionCount().then(res => {
      setShowLoader(false);
      if (res.status === 200) {
        setProdschdlCount(res.data.data);
      }
    }).catch((err) => {
      console.log(err)
    });
  };


  const mapData = (name) => {
    // console.log("name", name)
    if (name === 'softDemand') {
      getSoftDemandSiteWiseRisksMap().then(res => {
        setShowLoader(false)
        if (res.status === 200) {
          setSoftDemandMapData(res.data.data);
        }
      }).catch((err) => {
        console.log(err)
      });
    }
    else if (name === 'productionRisks') {
      // console.log("111");
      geProdschdlSiteWiseRisksMap().then(res => {
        setShowLoader(false)
        if (res.status === 200) {
          setProdschdlMapData(res.data.data);
        }
      }).catch((err) => {
        console.log(err)
      });
    }
  }


  const prodschdlBarChartAPI = () => {
    getprodschdlBar().then(res => {
      setShowLoader(false)
      if (res.status === 200) {
        // console.log("res111", res.data.data)
        setProdschdlBarchartData(res.data.data);
        setShowProdRisk(true)
      }
    }).catch((err) => {
      console.log(err)
    });
  }


  const softDemandBarChartAPI = () => {
    getSiteWiseRisksBar().then(res => {
      setShowLoader(false)
      if (res.status === 200) {
        // console.log("res111", res.data.data)
        setSoftDemandBarChartData(res.data.data);
      }
    }).catch((err) => {
      console.log(err)
    });
  }




  return (
    <div className="dashboard">
      <div className="row" style={{ marginBottom: 15 }}>
        <div className="col-md-4">
          <h2 class="m-0 text-dark">Risk Dashboard</h2>
        </div>
        <div className="col-md-8">
          <div className="row mb-0" style={{ justifyContent: 'right' }}>
            <div className="col-md-1" style={{ textAlign: 'right', marginTop: 5 }}><strong>Filter By: </strong></div>
            {(partyType === "Linesight" || partyType === "Supplier") &&
              <div className='col-md-3'>
                <div className="form-group">

                  <select disabled class="form-control form-control-sm" name="farmer">
                    <option value="">-Select Customer-</option>
                  </select>

                </div>
              </div>
            }
            {(partyType === "Customer" || partyType === "Contractor" || partyType === "Linesight") &&
              <div className='col-md-3 mb-0'>
                <div className="form-group">
                  <select disabled class="form-control form-control-sm" name="uom">
                    <option value="">-Select Site-</option>
                  </select>

                </div>
              </div>
            }
            <div className="col-md-2 mb-0">
              <Datepicker
                disabled
                placeholderText="Select From Date"
                maxDate={new Date()}
                //minDate={new Date(moment(batchSummary.startDateTime).format('YYYY-MM-DD'))}
                dateFormat="dd-MMM-yyyy"
                name="closeDate"
                className="form-control form-control-sm"
              >
              </Datepicker>
            </div>
            <div className="col-md-2 mb-0">
              <Datepicker
                disabled
                //selected={selectedDate} 
                //onChange = {dateSelector}
                //showTimeSelect
                //setDuration(moment(closeDate).diff(batchSummary.startDate))
                placeholderText="Select To Date"
                maxDate={new Date()}
                //minDate={new Date(moment(batchSummary.startDateTime).format('YYYY-MM-DD'))}
                dateFormat="dd-MMM-yyyy"
                name="closeDate"
                className="form-control form-control-sm"
              >
              </Datepicker>
            </div>
            <div className="col-md-1 mb-0">
              <button style={{ marginTop: 0 }} className="btn btn-sm btn-primary">Submit</button>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <InfoBox
          iconName="files"
          title="Soft Demand Risks"
          number={softDemandCount.riskCount}
          total={softDemandCount.totalCount}
          desc="Purchase requisition"
          onClick={() => handleBoxClick('softDemand')} // Bar and Map
          isActive={activeBox === 'softDemand'}
        />
        <InfoBox
          iconName="time"
          title="Supplier Production Risks"
          number={prodschdlCount.riskCount}
          total={prodschdlCount.totalCount}
          desc="Schedules"
          onClick={() => handleBoxClick('productionRisks')} // Bar and Map
          isActive={activeBox === 'productionRisks'}
        />
        <InfoBox
          iconName="truck"
          title="Logistics Risks"
          number="19"
          total="67"
          desc="Shipments"
          onClick={() => handleBoxClick('logisticRisks')} //widgets and Bar and no Map
          isActive={activeBox === 'logisticRisks'}
        />
        <InfoBox
          iconName="dashboard"
          title="QA/QC Installation Risks"
          number="29"
          total="74"
          desc="Installations"
          onClick={() => handleBoxClick('qaInstallation')} // Widgets and Map
          isActive={activeBox === 'qaInstallation'}
        />
        <InfoBox
          iconName="layout"
          title="Inventory Risks"
          number="6"
          total="8"
          desc="Sites"
          onClick={() => handleBoxClick('inventoryRisks')} //Line Chart with Tables
          isActive={activeBox === 'inventoryRisks'}
        />
      </div>
      {
        activeBox == 'logisticRisks' ?


          <div className="row" id="logisticRisks">
            <div className="col-md-8">
              <div className="card">
                <div className="card-body">
                  <LogisticsRisks></LogisticsRisks>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
                <div className="card-body">
                  <h2>Logistics Risks</h2>
                  <table class="table table-condensed table-striped">
                    <tbody>
                      <tr>
                        <td><span><FiFileText size={20}></FiFileText></span> &nbsp;Pending Booking Requests</td>
                        <td className="number">02</td>
                      </tr>
                      <tr>
                        <td><span><FiAlertOctagon size={20}></FiAlertOctagon></span> &nbsp;Pickup Deviations</td>
                        <td className="number">01</td>
                      </tr>
                      <tr>
                        <td><span><FiLogOut size={20}></FiLogOut></span> &nbsp;Export Clearance Risk</td>
                        <td className="number">04</td>
                      </tr>
                      <tr>
                        <td><span><FiTruck size={20}></FiTruck></span> &nbsp;IN-Transit Risks</td>
                        <td className="number">06</td>
                      </tr>
                      <tr>
                        <td><span><FiLogIn size={20}></FiLogIn></span> &nbsp;Import Clearance Check</td>
                        <td className="number">04</td>
                      </tr>
                      <tr>
                        <td><span><FiMapPin size={20}></FiMapPin></span> &nbsp;Final Delivery Deviation</td>
                        <td className="number">02</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          : <></>
      }
      {
        (activeBox == 'inventoryRisks') ?
          <>
            <div className="row" id="inventoryRisks">
              <div className="col-md-6">
                <div className="card">
                  <div className="card-body">
                    <h2>Inventory Planned vs Shortage
                      <IoEyeOutline title="View Details" style={{ float: "right", color: "black", fontSize: "20px", cursor: "pointer" }} onClick={InventoryPlannedvsShortage} />
                    </h2>
                    <InventoryChart></InventoryChart>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card">
                  <div className="card-body">
                    <table class="table table-condensed table-striped">
                      <tbody>
                        <tr>
                          <td><h2>Inventory Risks</h2></td>
                          <td># of Sites</td>
                          <td># Material</td>
                        </tr>
                        <tr>
                          <td><span><FiFileText size={20}></FiFileText></span> &nbsp;Inventory Planned Vs Shortage</td>
                          <td className=""><span className="">7 /8</span></td>
                          <td className=""><span className="">08</span></td>
                        </tr>
                        <tr className="tableRowClick" onClick={ApproachingOutofStock}>
                          <td><span><FiAlertOctagon size={20}></FiAlertOctagon></span> &nbsp;Inventory Approaching Out of Stock</td>
                          <td className=""><span className="">1 /8</span></td>
                          <td className=""><span className="">10</span></td>
                        </tr>
                        <tr>
                          <td><span><GiHealthIncrease size={20}></GiHealthIncrease></span> &nbsp;Inventory with Excess QTY</td>
                          <td className=""> 2 /8</td>
                          <td className="">06</td>
                        </tr>
                        <tr className="tableRowClick" onClick={OutofStockInventory}>
                          <td><span><MdOutlinePianoOff size={20}></MdOutlinePianoOff></span> &nbsp;Out of Stock Inventory</td>
                          <td className="">1 /8</td>
                          <td className="">09</td>
                        </tr>
                        <tr className="tableRowClick" onClick={Damage}>
                          <td><span><RiFileDamageLine size={20}></RiFileDamageLine></span> &nbsp;Avg Damage %</td>
                          <td className="number" style={{ color: "red" }}>6%</td>
                          <td className="number"></td>
                        </tr>
                        {/* <tr>
                          <td><span><FiMapPin size={20}></FiMapPin></span> &nbsp;Final Delivery Deviation</td>
                          <td className="number">09</td>
                        </tr> */}
                      </tbody>
                    </table>

                  </div>
                </div>
              </div>
            </div>
            <div className="row" id="inventoryRisks">
              <div className="col-md-6">
                <div className="card">
                  <div className="card-body">
                    <h2 style={{ marginBottom: 15 }}>Inventory Approaching Out of Stock</h2>
                    <div className="row">
                      <div className="col-md-2"><FiFilter size={16}></FiFilter> Filter: </div>
                      <div className="col-md-4">
                        <select class="form-control form-control-sm" name="siteID"><option value="">-By Site-</option><option>Eureka Park</option><option>Serein</option><option>Rio De Goa</option><option>Myst</option><option>La Vida</option></select>
                      </div>
                      <div className="col-md-4">
                        <select class="form-control form-control-sm" name="siteID"><option value="">-By Material-</option><option>Eureka Park</option><option>Serein</option><option>Rio De Goa</option><option>Myst</option><option>La Vida</option></select>
                      </div>
                    </div>
                    <div className="tableArea">
                      <DataTable
                        columns={appriachingInvColumns}
                        data={approachingStockData}
                        dense
                        selectableRowsHighlight='true'
                        compact
                        highlightOnHover='true'
                        striped
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card">
                  <div className="card-body">
                    <h2 style={{ marginBottom: 15 }}>Out of Stock Inventory</h2>
                    <div className="row">
                      <div className="col-md-2"><FiFilter size={16}></FiFilter> Filter: </div>
                      <div className="col-md-4">
                        <select class="form-control form-control-sm" name="siteID"><option value="">-By Site-</option><option>Eureka Park</option><option>Serein</option><option>Rio De Goa</option><option>Myst</option><option>La Vida</option></select>
                      </div>
                      <div className="col-md-4">
                        <select class="form-control form-control-sm" name="siteID"><option value="">-By Material-</option><option>Eureka Park</option><option>Serein</option><option>Rio De Goa</option><option>Myst</option><option>La Vida</option></select>
                      </div>
                    </div>
                    <div className="tableArea">
                      <DataTable
                        columns={requiredStockColumns}
                        data={requiredStock}
                        dense
                        selectableRowsHighlight='true'
                        compact
                        highlightOnHover='true'
                        striped
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>

          : ''
      }
      {
        activeBox == 'qaInstallation' ?
          <div className="row" id="qaRisks">
            <div className="col-md-8">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-3">
                      <h2 class="m-0 text-dark">Site wise risks</h2>
                    </div>
                    <div className="col-md-9">
                      <ul className="statesLegends">
                        <li><span className="businessStates">&nbsp;</span> Business Sates</li>
                        <li><span className="nonBusinessStates">&nbsp;</span> Non Business States</li>
                        <li><span className="riskSates">&nbsp;</span> Risks Identified</li>
                        <li><span className="idealSates">&nbsp;</span> Ideal</li>
                      </ul>
                    </div>
                  </div>
                  <USACQCMap redirecto={'impactedWorks'}></USACQCMap>
                  {/* <USAChoroplethMap redirecto={'impactedWorks'}></USAChoroplethMap> */}
                </div>
              </div>
            </div>
            <div className="col-md-4" >
              <div className="card">
                <div className="card-body">
                  <h2>QA/QC Installation Risks</h2>
                  <table class="table table-condensed table-striped">
                    <tbody>
                      {/* <tr>
                        <td><span><MdOutlineSettings size={20}></MdOutlineSettings></span> &nbsp;Delay in Production</td>
                        <td className="number">09</td>
                      </tr> */}
                      <tr>
                        <td><span><FiAlertOctagon size={20}></FiAlertOctagon></span> &nbsp;Delay in FAT</td>
                        <td className="number">02</td>
                      </tr>
                      <tr>
                        <td><span><MdLocalShipping size={20}></MdLocalShipping></span> &nbsp;Delay in Logistics</td>
                        <td className="number">04</td>
                      </tr>
                      <tr>
                        <td><span><VscLibrary size={20}></VscLibrary></span> &nbsp;Pending goods issue</td>
                        <td className="number">07</td>
                      </tr>
                      <tr>
                        <td><span><GrMap size={20}></GrMap></span> &nbsp;Awaiting delivery at the site</td>
                        <td className="number">03</td>
                      </tr>
                      <tr>
                        <td><span><AiOutlineGold size={20}></AiOutlineGold></span> &nbsp;Pending installation by the supplier</td>
                        <td className="number">06</td>
                      </tr>
                      <tr>
                        <td><span><AiOutlineDoubleLeft size={20}></AiOutlineDoubleLeft></span> &nbsp;Awaiting confirmation from Line Sight</td>
                        <td className="number">02</td>
                      </tr>
                      <tr>
                        <td><span><AiOutlineGlobal size={20}></AiOutlineGlobal></span> &nbsp;Pending confirmation from the customer</td>
                        <td className="number">05</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          : <></>
      }
      <div className="row">
        {
          (activeBox == 'softDemand') ?
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <ProductForecast softDemandBarChart={softDemandBarChartData}></ProductForecast>
                </div>
              </div>
            </div>
            : <></>
        }
        {
          (activeBox == 'productionRisks') && showProdRisk ?
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <ProductionSchedules prodschdlBarChart={prodschdlBarchartData}></ProductionSchedules>
                </div>
              </div>
            </div>
            : <></>
        }

        {
          (activeBox == 'softDemand' || activeBox == 'productionRisks') ?

            <div className="col-md-12" key={Math.random()}>
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-4">
                      <h2 class="m-0 text-dark">Site wise risks</h2>
                    </div>
                    <div className="col-md-8">
                      <ul className="statesLegends">
                        <li><span className="businessStates">&nbsp;</span> Business Sates</li>
                        <li><span className="nonBusinessStates">&nbsp;</span> Non Business States</li>
                        <li><span className="riskSates">&nbsp;</span> Risks Identified</li>
                        <li><span className="idealSates">&nbsp;</span> On Track</li>
                      </ul>
                    </div>
                  </div>
                  <USAChoroplethMap
                    redirecto=
                    {activeBox === "softDemand" ? 'calendarView' :
                      activeBox === "productionRisks" ? 'productionRiskWorkSchedules' : ""
                    }
                    mapData={activeBox === "softDemand" ? softDemandMapData :
                      activeBox === "productionRisks" ? prodschdlMapData : ""}
                  ></USAChoroplethMap>
                </div>

              </div>
            </div>
            : <></>}
      </div>
      {showLoader ?
        <section {...containerProps} style={{ "margin-top": "0px", display: 'inline' }}>
          {indicatorEl} {/* renders only while loading */}
        </section> : ""
      }
    </div >
  );
};
export default Dashboard;
