import DataTable from "react-data-table-component";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useHistory } from 'react-router-dom';
const Damage = () => {
    const columns = [
        {
            name: "PRODUCT ID",
            selector: "PRODUCTID",
            sortable: true
        },
        {
            name: "SUPPLIER ID",
            selector: "SUPPLIERID",
            sortable: true
        },
        {
            name: "SITE ID",
            selector: "SiteId",
            sortable: true
        },
        {
            name: "LOCATION",
            selector: "LOCATION",
            sortable: true
        },
        {
            name: "DAMAGE QTY",
            selector: "DAMAGEQUANTITY",
            sortable: true,
            cell: row => (
                <span className='redcolor'>{row.DAMAGEQUANTITY} {row.uom}
                </span>
            )
        },
        {
            name: "DAMAGE QTY (%)",
            selector: "DAMAGEQUANTITYPER",
            sortable: true,
            cell: row => (
                <span className='redcolor'>{row.DAMAGEQUANTITYPER} {row.uom}
                </span>
            )
        }
    ];
    const data = [
        {
            PRODUCTID: "IT001",
            SUPPLIERID: "SUP001",
            SiteId: "SITE001",
            LOCATION: "New York",
            DAMAGEQUANTITY: "53 EA",
            DAMAGEQUANTITYPER: "2%",
        },
        {
            PRODUCTID: "IT005",
            SUPPLIERID: "SUP001",
            SiteId: "SITE001",
            LOCATION: "New York",
            DAMAGEQUANTITY: "53 EA",
            DAMAGEQUANTITYPER: "2%",
        },
        {
            PRODUCTID: "IT009",
            SUPPLIERID: "SUP001",
            SiteId: "SITE001",
            LOCATION: "New York",
            DAMAGEQUANTITY: "53 EA",
            DAMAGEQUANTITYPER: "2%",
        },
        {
            PRODUCTID: "IT0012",
            SUPPLIERID: "SUP001",
            SiteId: "SITE001",
            LOCATION: "New York",
            DAMAGEQUANTITY: "53 EA",
            DAMAGEQUANTITYPER: "2%",
        },
        {
            PRODUCTID: "IT0019",
            SUPPLIERID: "SUP001",
            SiteId: "SITE001",
            LOCATION: "New York",
            DAMAGEQUANTITY: "53 EA",
            DAMAGEQUANTITYPER: "2%",
        }, {
            PRODUCTID: "IT0021",
            SUPPLIERID: "SUP001",
            SiteId: "SITE001",
            LOCATION: "New York",
            DAMAGEQUANTITY: "53 EA",
            DAMAGEQUANTITYPER: "2%",
        }


    ];
    const history = useHistory();

    const handleBackClick = () => {
        history.push('/dashboard', { propActiveState: 'inventoryRisks' });
    };
    return (
        <div className="farmMaster">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h2 class="m-0 text-dark">DAMAGE INVENTORY</h2>
                </div>
                <div class="col-md-6">
                    <span style={{ display: "flex", justifyContent: "end" }}>
                        <div className="btnBack" onClick={handleBackClick}>
                            <IoMdArrowRoundBack size={20}></IoMdArrowRoundBack>&nbsp; Back
                        </div>
                    </span>
                </div>
            </div>
            <div className="card">
                <div class="card-body">
                    <div className="row">
                        <div className="col-4">
                            <h2 class="m-0 text-dark tableHeading">DAMAGE INVENTORY (%) - TOP 5 SITES</h2>
                            <br></br>
                            <table class="table table-condensed table-striped">
                                <tbody>
                                    <tr>
                                        <th>SITE ID</th>
                                        <th>LOCATION</th>
                                        <th>DAMAGE INVENTORY (%)</th>
                                    </tr>
                                    <tr>
                                        <td>SITE001</td>
                                        <td>New York</td>
                                        <td>6%</td>
                                    </tr>
                                    <tr>
                                        <td>SITE002</td>
                                        <td>San Francisco</td>
                                        <td>6%</td>
                                    </tr>
                                    <tr>
                                        <td>SITE003</td>
                                        <td>Seattle</td>
                                        <td>5%</td>
                                    </tr>
                                    <tr>
                                        <td>SITE004</td>
                                        <td>Dallas</td>
                                        <td>5%</td>
                                    </tr>
                                    <tr>
                                        <td>SITE005</td>
                                        <td>Philadelphia</td>
                                        <td>4%</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="col-4">
                            <h2 class="m-0 text-dark tableHeading">DAMAGE INVENTORY (%) - TOP 5 PRODUCTS</h2>
                            <br></br>
                            <table class="table table-condensed table-striped">
                                <tbody>
                                    <tr>
                                        <th>PRODUCT ID</th>
                                        <th>DAMAGE QUANITY</th>
                                        <th>DAMAGE INVENTORY (%)</th>
                                    </tr>
                                    <tr>
                                        <td>PROD02</td>
                                        <td>16 EA</td>
                                        <td>6%</td>
                                    </tr>
                                    <tr>
                                        <td>PROD05</td>
                                        <td>22 EA</td>
                                        <td>6%</td>
                                    </tr>
                                    <tr>
                                        <td>PROD7</td>
                                        <td>27 EA</td>
                                        <td>5%</td>
                                    </tr>
                                    <tr>
                                        <td>PROD09</td>
                                        <td>31 EA</td>
                                        <td>5%</td>
                                    </tr>
                                    <tr>
                                        <td>PROD010</td>
                                        <td>36 EA</td>
                                        <td>4%</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="col-4">
                            <h2 class="m-0 text-dark tableHeading">DAMAGE INVENTORY (%) - TOP 5 SUPPLIERS</h2>
                            <br></br>
                            <table class="table table-condensed table-striped">
                                <tbody>
                                    <tr>
                                        <th>SUPPLIER ID</th>
                                        <th>PRODUCT ID</th>
                                        <th>DAMAGE QTY</th>
                                        <th>DAMAGE INV (%)</th>
                                    </tr>
                                    <tr>
                                        <td>SUP001</td>
                                        <td>PROD01</td>
                                        <td>18 EA</td>
                                        <td>6%</td>
                                    </tr>
                                    <tr>
                                        <td>SUP001</td>
                                        <td>PROD03</td>
                                        <td>18 EA</td>
                                        <td>6%</td>
                                    </tr>
                                    <tr>
                                        <td>SUP001</td>
                                        <td>PROD010</td>
                                        <td>18 EA</td>
                                        <td>6%</td>
                                    </tr>
                                    <tr>
                                        <td>SUP001</td>
                                        <td>PROD014</td>
                                        <td>18 EA</td>
                                        <td>6%</td>
                                    </tr>
                                    <tr>
                                        <td>SUP001</td>
                                        <td>PROD015</td>
                                        <td>18 EA</td>
                                        <td>6%</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <br></br>
                    <div className="title row">
                        <h2 class="m-2 text-dark">REPORT</h2>
                    </div>
                    <br></br>
                    <div className="row">
                        <form className="col-md-12">
                            <div className='row'>
                                <div className='col-md-4'>
                                    <div className="form-group">
                                        <label htmlFor>Site Name</label>
                                        <select class="form-control form-control-sm" name="farmer">
                                            <option value="">-Select-</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className="form-group">
                                        <label htmlFor>Product ID</label>
                                        <select class="form-control form-control-sm" name="farmer">
                                            <option value="">-Select-</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div> <br></br>
                    <div className='row'>
                        <div className='col-md-12'>
                            <DataTable
                                columns={columns}
                                data={data}
                                defaultSortField="batchID"
                                pagination
                                //selectableRows
                                // selectableRowsComponent={BootyCheckbox}
                                // onSelectedRowsChange = {handleChange}
                                dense
                                selectableRowsHighlight='true'
                                compact
                                highlightOnHover='true'
                                striped
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Damage;