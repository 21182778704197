import React, { useEffect, useState, useRef } from 'react';
import FinalizedPO from "../reports/finalizedPO";
import { Tabs, Tab } from 'react-bootstrap-tabs';
import POReport from '../reports/poreport';
import RejectedPO from '../reports/rejectedPOs'
import Datepicker from 'react-datepicker';
import { useForm, Controller } from "react-hook-form";
import { productionScheduleList, poLinesList, userInfo, purchaseOrderInProduction } from '../pages/const';
import { MdFileUpload } from "react-icons/md";
import { AiOutlineFileExcel } from "react-icons/ai";

import moment from 'moment';
const SupplierPo = (props) => {
    const [partyType, setPartyType] = useState(sessionStorage.getItem('partyType'));
    const [siteList, setSiteList] = useState([])
    const [customerList, setCustomerList] = useState([])
    const [showLoader, setShowLoader] = useState(false);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [data, setData] = useState([])
    const [isSubmit, setIsSubmit] = useState(false)
    const { register, handleSubmit, getValues, watch, formState: { errors } } = useForm({
        mode: 'onChange'
    });
    const onSubmit = (e) => {
        let submitPayload = {
            fromDate: moment(fromDate).add(1, 'day').toISOString(),
            toDate: moment(toDate).add(1, 'day').toISOString(),
            siteId: e.site
        }
        setIsSubmit(true)
        setShowLoader(true)
        purchaseOrderInProduction(submitPayload).then(res => {
            setShowLoader(false);
            if (res.status === 200) {
                setData(res.data.data)

            }
        }).catch((err) => {
            console.log(err)
        });
    }
    const onHandleChangeSite = (e) => {

    }
    const onHandleChangeCustomer = (e) => {

    }
    const fromdateSelector = (date) => {
        setFromDate(date);
    }
    const todateSelector = (date) => {
        setToDate(date);
    }
    const filtersData = () => {
        userInfo().then(res => {
            if (res.status === 200) {
                setSiteList(res.data.data.siteIds)
                setCustomerList(res.data.data.customerIds)
                // setShowLoader(false)
            }
        }).catch((err) => {
            console.log(err)
        });
    }
    useEffect(() => {
        filtersData()

    }, []);
    return (
        <div className="planning">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h2 class="m-0 text-dark">Purchase Orders</h2>
                </div>
            </div>
            <div className='card'>
                <div className='card-body'>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-md-12 mb-0" style={{}}>
                                <div className="row">
                                    <div className="col-md-1" style={{ textAlign: 'left', marginTop: 5 }}><strong>Filter By: </strong></div>
                                </div>
                                <div className="row mb-0">
                                    {(partyType === "Linesight" || partyType === "Supplier") &&
                                        <div className='col-md-2'>
                                            <div className="form-group">
                                                <select class="form-control form-control-sm" name="farmer" {...register("customer", {
                                                    onChange: (e) => { onHandleChangeCustomer(e) }
                                                })}>
                                                    <option value="">-Select Customer-</option>
                                                    {customerList.map((item) => (
                                                        <option value={item}>{item}</option>
                                                    ))}
                                                </select>

                                            </div>
                                        </div>
                                    }
                                    {(partyType === "Customer" || partyType === "Contractor" || partyType === "Linesight") &&
                                        <div className='col-md-2 mb-0'>
                                            <div className="form-group">
                                                <select class="form-control form-control-sm" name="uom" {...register("site", {
                                                    onChange: (e) => { onHandleChangeSite(e) }
                                                })}>
                                                    <option value="">-Select Site-</option>
                                                    {siteList.map((item) => (
                                                        <option value={item}>{item}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    }
                                    <div className="col-md-2 mb-0">
                                        <Datepicker
                                            selected={fromDate}
                                            onChange={fromdateSelector}
                                            //showTimeSelect
                                            //setDuration(moment(closeDate).diff(batchSummary.startDate))
                                            placeholderText="Select From Date"

                                            //minDate={new Date(moment(batchSummary.startDateTime).format('YYYY-MM-DD'))}
                                            dateFormat="dd-MMM-yyyy"
                                            name="fromDate"
                                            className="form-control form-control-sm"

                                        >
                                        </Datepicker>
                                    </div>
                                    <div className="col-md-2 mb-0">
                                        <Datepicker
                                            selected={toDate}
                                            onChange={todateSelector}
                                            placeholderText="Select To Date"
                                            dateFormat="dd-MMM-yyyy"
                                            name="closeDate"
                                            className="form-control form-control-sm"
                                        >
                                        </Datepicker>
                                    </div>
                                    <div className="col mb-0">
                                        <button style={{ marginTop: 0 }} className="btn btn-sm btn-primary">Submit</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className="card">
                <div class="card-body">
                    <Tabs activeHeaderStyle={{ background: 'transparent' }}>
                        <Tab label="Soft Demand (Purchase Request)" eventKey="Soft Demand PO">
                            {/* <div style={{ display: 'flex', justifyContent: 'end', marginBottom: '10px' }}>
                                <div>
                                    <button type="button" class="btn btn-primary">
                                        <AiOutlineFileExcel /> Export to Excel
                                    </button>
                                </div>
                            </div> */}
                            <POReport data={data} submitData={isSubmit}></POReport>
                        </Tab>




                        <Tab label="Finalized Orders (From ERP)" eventKey="Finalized Demand">
                            <div style={{ display: 'flex', justifyContent: 'end', marginBottom: '10px' }}>
                                <div>
                                    <button type="button" class="btn btn-secondary">
                                        <MdFileUpload color='white' />&nbsp;Upload </button>
                                </div>
                            </div>
                            <FinalizedPO data={data} submitData={isSubmit}></FinalizedPO>
                        </Tab>
                    </Tabs>

                </div>
            </div>
        </div>
    );
}

export default SupplierPo;