import React, { useState } from 'react';
import DataTable from "react-data-table-component";
import { useHistory } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import Datepicker from 'react-datepicker';

export default function ShipmentsDeviations() {
    const selectedWorkId = sessionStorage.getItem('selectedWorkId')
    const [partyType, setPartyType] = useState(sessionStorage.getItem('partyType'));

    const data = [
        { id: 1, tracking: 'TRCK001', orderId: 'PO001', modeOfTransport: 'Ocean', supplierId: 'SUP001', forwarder: 'FWD001', carrier: 'CR001', shippingSite: 'SITE001', actualETA: '05-Mar-2023', changeInETA: '02-Apr-2024', deviationDays: '27 Days', currentStatus: 'Yet to Pickup' },
        { id: 2, tracking: 'TRCK002', orderId: 'PO002', modeOfTransport: 'Ocean', supplierId: 'SUP002', forwarder: 'FWD001', carrier: 'CR002', shippingSite: 'SITE001', actualETA: '05-Mar-2023', changeInETA: '12-Mar-2024', deviationDays: '07 Days', currentStatus: 'Pending Booking' },
        { id: 3, tracking: 'TRCK003', orderId: 'PO003', modeOfTransport: 'Ocean', supplierId: 'SUP003', forwarder: 'FWD001', carrier: 'CR003', shippingSite: 'SITE001', actualETA: '05-Mar-2023', changeInETA: '11-Mar-2024', deviationDays: '06 Days', currentStatus: 'Pre-Carriage' },
        { id: 4, tracking: 'TRCK004', orderId: 'PO004', modeOfTransport: 'Ocean', supplierId: 'SUP004', forwarder: 'FWD001', carrier: 'CR004', shippingSite: 'SITE001', actualETA: '05-Mar-2023', changeInETA: '28-Mar-2024', deviationDays: '23 Days', currentStatus: 'Yet to Pickup' },
        { id: 5, tracking: 'TRCK005', orderId: 'PO005', modeOfTransport: 'Ocean', supplierId: 'SUP005', forwarder: 'FWD001', carrier: 'CR005', shippingSite: 'SITE001', actualETA: '05-Mar-2023', changeInETA: '14-Mar-2024', deviationDays: '09 Days', currentStatus: 'On-Carriage' },
        { id: 6, tracking: 'TRCK006', orderId: 'PO006', modeOfTransport: 'Ocean', supplierId: 'SUP006', forwarder: 'FWD001', carrier: 'CR005', shippingSite: 'SITE001', actualETA: '05-Mar-2023', changeInETA: '11-Mar-2024', deviationDays: '06 Days', currentStatus: 'On-Carriage' },
        { id: 7, tracking: 'TRCK007', orderId: 'PO007', modeOfTransport: 'Ocean', supplierId: 'SUP007', forwarder: 'FWD001', carrier: 'CR005', shippingSite: 'SITE001', actualETA: '05-Mar-2023', changeInETA: '17-Mar-2024', deviationDays: '12 Days', currentStatus: 'On-Carriage' },
        { id: 8, tracking: 'TRCK008', orderId: 'PO008', modeOfTransport: 'Ocean', supplierId: 'SUP001', forwarder: 'FWD001', carrier: 'CR001', shippingSite: 'SITE001', actualETA: '05-Mar-2023', changeInETA: '27-Mar-2024', deviationDays: '22 Days', currentStatus: 'Yet to Pickup' },
        { id: 9, tracking: 'TRCK009', orderId: 'PO009', modeOfTransport: 'Ocean', supplierId: 'SUP002', forwarder: 'FWD001', carrier: 'CR002', shippingSite: 'SITE001', actualETA: '05-Mar-2023', changeInETA: '12-Mar-2024', deviationDays: '07 Days', currentStatus: 'Pending Booking' },
        { id: 10, tracking: 'TRCK0010', orderId: 'PO0010', modeOfTransport: 'Ocean', supplierId: 'SUP003', forwarder: 'FWD001', carrier: 'CR003', shippingSite: 'SITE001', actualETA: '05-Mar-2023', changeInETA: '07-Mar-2024', deviationDays: '02 Days', currentStatus: 'Pre-Carriage' },
        { id: 11, tracking: 'TRCK0011', orderId: 'PO0011', modeOfTransport: 'Ocean', supplierId: 'SUP004', forwarder: 'FWD001', carrier: 'CR004', shippingSite: 'SITE001', actualETA: '05-Mar-2023', changeInETA: '29-Mar-2024', deviationDays: '24 Days', currentStatus: 'Yet to Pickup' },
        { id: 12, tracking: 'TRCK0012', orderId: 'PO0012', modeOfTransport: 'Ocean', supplierId: 'SUP005', forwarder: 'FWD001', carrier: 'CR005', shippingSite: 'SITE001', actualETA: '05-Mar-2023', changeInETA: '14-Mar-2024', deviationDays: '09 Days', currentStatus: 'On-Carriage' },
        { id: 13, tracking: 'TRCK0013', orderId: 'PO0013', modeOfTransport: 'Ocean', supplierId: 'SUP006', forwarder: 'FWD001', carrier: 'CR005', shippingSite: 'SITE001', actualETA: '05-Mar-2023', changeInETA: '05-Apr-2024', deviationDays: '30 Days', currentStatus: 'On-Carriage' },
        { id: 14, tracking: 'TRCK0014', orderId: 'PO0014', modeOfTransport: 'Ocean', supplierId: 'SUP007', forwarder: 'FWD001', carrier: 'CR005', shippingSite: 'SITE001', actualETA: '05-Mar-2023', changeInETA: '23-Mar-2024', deviationDays: '18 Days', currentStatus: 'On-Carriage' },
        { id: 15, tracking: 'TRCK0015', orderId: 'PO0015', modeOfTransport: 'Ocean', supplierId: 'SUP001', forwarder: 'FWD001', carrier: 'CR001', shippingSite: 'SITE001', actualETA: '05-Mar-2023', changeInETA: '30-Mar-2024', deviationDays: '25 Days', currentStatus: 'Yet to Pickup' },
        { id: 16, tracking: 'TRCK0016', orderId: 'PO0016', modeOfTransport: 'Ocean', supplierId: 'SUP002', forwarder: 'FWD001', carrier: 'CR002', shippingSite: 'SITE001', actualETA: '05-Mar-2023', changeInETA: '18-Mar-2024', deviationDays: '13 Days', currentStatus: 'Pending Booking' },
        { id: 17, tracking: 'TRCK0017', orderId: 'PO0017', modeOfTransport: 'Ocean', supplierId: 'SUP003', forwarder: 'FWD001', carrier: 'CR003', shippingSite: 'SITE001', actualETA: '05-Mar-2023', changeInETA: '05-Apr-2024', deviationDays: '30 Days', currentStatus: 'Pre-Carriage' },
        { id: 18, tracking: 'TRCK0018', orderId: 'PO0018', modeOfTransport: 'Ocean', supplierId: 'SUP004', forwarder: 'FWD001', carrier: 'CR004', shippingSite: 'SITE001', actualETA: '05-Mar-2023', changeInETA: '03-Apr-2024', deviationDays: '28 Days', currentStatus: 'Yet to Pickup' },
        { id: 19, tracking: 'TRCK0019', orderId: 'PO0019', modeOfTransport: 'Ocean', supplierId: 'SUP005', forwarder: 'FWD001', carrier: 'CR005', shippingSite: 'SITE001', actualETA: '05-Mar-2023', changeInETA: '13-Mar-2024', deviationDays: '08 Days', currentStatus: 'On-Carriage' },

    ];
    const columns = [
        {
            name: "Tracking #",
            selector: "tracking",
            sortable: true,
            minWidth: '150px',
            cell: (row) => <a
                style={{
                    width: "100%",
                    lineHeight: 0.5,
                    color: "blue",
                    cursor: 'pointer'
                }}
                onClick={() => shipmentTracking()}
            >
                {row.tracking}
            </a>
        },
        {
            name: "Order ID ",
            selector: 'orderId',
            width: '100px',
            cell: (row) => <a
                style={{
                    width: "100%",
                    lineHeight: 0.5,
                    color: "blue",
                    cursor: 'pointer'
                }}
            // onClick={() => shipmentTracking(row)}
            >
                {row.orderId}
            </a>
        },
        {
            name: "Mode Of Transport",
            selector: "modeOfTransport",
            sortable: true,
            minWidth: '200px'
        },
        {
            name: "Supplier ID",
            selector: "supplierId",
            sortable: true,
            minWidth: '150px'
        },
        {
            name: "Forwarder",
            selector: "forwarder",
            sortable: true,
            minWidth: '150px'
        },
        {
            name: "Carrier",
            selector: "carrier",
            sortable: true,
            minWidth: '80px'
        },
        {
            name: "Shipment Site",
            selector: "shippingSite",
            sortable: true,
            minWidth: '150px'
        },
        {
            name: "ETA",
            selector: "actualETA",
            sortable: true,
            minWidth: '150px'
        },
        {
            name: "Change In ETA",
            selector: "changeInETA",
            sortable: true,
            minWidth: '150px'
        },
        {
            name: "Deviation Days",
            selector: "deviationDays",
            sortable: true,
            minWidth: '150px'
        },
        {
            name: "Current Status",
            selector: "currentStatus",
            sortable: true,
            minWidth: '200px'
        },

    ];
    const history = useHistory();
    const shipmentTracking = () => {
        history.push('/shipmentTracking');
    }
    const handleBackClick = () => {
        history.push('/dashboard', { propActiveState: 'logisticRisks' });
    };
    return (
        <div className="farmMaster">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h2 class="m-0 text-dark">In Transit</h2>
                    {/* <span style={{ fontSize: 13 }}>
                            <strong>Work ID: </strong> <span className='textHighlighter'>{selectedWorkId}</span>
                        </span> */}
                </div>


                <div class="col-6">
                    <span style={{ display: "flex", justifyContent: "end" }}>
                        <div className="btnBack" onClick={handleBackClick}>
                            <IoMdArrowRoundBack size={20}></IoMdArrowRoundBack>&nbsp; Back
                        </div>
                    </span>
                </div>
            </div>

            <div className='card'>
                <div className='card-body'>
                    <form>
                        <div className="row">
                            <div className="col-md-12 mb-0" style={{}}>
                                <div className="row">
                                    <div className="col-md-1" style={{ textAlign: 'left', marginTop: 5 }}><strong>Filter By: </strong></div>
                                </div>
                                <div className="row mb-0">
                                    {(partyType === "Linesight" || partyType === "Supplier") &&
                                        <div className='col-md-2'>
                                            <div className="form-group">
                                                <select class="form-control form-control-sm" name="farmer">
                                                    <option value="">-Select Customer-</option>

                                                </select>

                                            </div>
                                        </div>
                                    }
                                    {(partyType === "Customer" || partyType === "Contractor" || partyType === "Linesight") &&
                                        <div className='col-md-2 mb-0'>
                                            <div className="form-group">
                                                <select class="form-control form-control-sm" name="uom">
                                                    <option value="">-Select Site-</option>
                                                </select>
                                            </div>
                                        </div>
                                    }
                                    <div className="col-md-2 mb-0">
                                        <Datepicker
                                            //showTimeSelect
                                            //setDuration(moment(closeDate).diff(batchSummary.startDate))
                                            placeholderText="Select From Date"

                                            //minDate={new Date(moment(batchSummary.startDateTime).format('YYYY-MM-DD'))}
                                            dateFormat="dd-MMM-yyyy"
                                            name="fromDate"
                                            className="form-control form-control-sm"

                                        >
                                        </Datepicker>
                                    </div>
                                    <div className="col-md-2 mb-0">
                                        <Datepicker
                                            placeholderText="Select To Date"
                                            dateFormat="dd-MMM-yyyy"
                                            name="closeDate"
                                            className="form-control form-control-sm"
                                        >
                                        </Datepicker>
                                    </div>
                                    <div className="col mb-0">
                                        <button style={{ marginTop: 0 }} className="btn btn-sm btn-primary">Submit</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div className="chips-container">
                <div key={React} className="chip" style={{ background: "pink" }}>All </div>
                <div key={React} className="chip"> {" < 2 Days"}  </div>
                <div key={React} className="chip"> {">2 Days <1 Week"} </div>
                <div key={React} className="chip"> {">1 Week - <15 Days"} </div>
                <div key={React} className="chip"> {">15 Days - <1 Month"} </div>
                <div key={React} className="chip"> {"> 1 Month"} </div>
                {/* <button className="close-btn" >x</button> */}
            </div>

            <div className='card'>
                <div class="card-body">
                    <div class="mt-2">
                        <DataTable
                            columns={columns}
                            data={data}
                            pagination
                            selectableRowsHighlight='true'
                            dense
                            compact
                            highlightOnHover='true'
                            striped
                        />
                    </div>

                </div>

            </div>

        </div>
    )
}
