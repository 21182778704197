import { useState } from "react"
import { Tabs, Tab } from 'react-bootstrap-tabs';
import DataTable from "react-data-table-component";

const Reports = () => {
    const [partyType, setPartyType] = useState(sessionStorage.getItem('partyType'));
    const columns = [
        {
            name: "Site ID",
            selector: "siteId",
            sortable: true,
        },
        {
            name: "Contractor ID",
            selector: "contractorId",
            sortable: true,
        },
        {
            name: "Work ID",
            selector: "workId",
            sortable: true,
        },
        {
            name: "Work Type",
            selector: "workType",
            sortable: true,
        },
        {
            name: "Work Description",
            selector: "workDescription",
            sortable: true,
        },
        {
            name: "Work Start Date",
            selector: "workStartDate",
            sortable: true,
        },
        {
            name: "Work End Date",
            selector: "workEndDate",
            sortable: true,
        },
        {
            name: "Task ID",
            selector: "taskId",
            sortable: true,
        },
        {
            name: "Task Description",
            selector: "taskDescription",
            sortable: true,
        },
        {
            name: "Task Predecessors",
            selector: "taskPredecessors",
            sortable: true,
        },
        {
            name: "Task Successors",
            selector: "taskSuccessors",
            sortable: true,
        },
        {
            name: "Required Skill",
            selector: "requiredSkill",
            sortable: true,
        },
        {
            name: "Manpower Required",
            selector: "manpowerRequired",
            sortable: true,
        },
        {
            name: "Material Category",
            selector: "materialCategory",
            sortable: true,
        },
        {
            name: "Material ID",
            selector: "materialId",
            sortable: true,
        },
        {
            name: "Material Name",
            selector: "materialName",
            sortable: true,
        },
        {
            name: "Material Description",
            selector: "materialDescription",
            sortable: true,
        },
        {
            name: "QTY",
            selector: "quantity",
            sortable: true,
        },
        {
            name: "UOM",
            selector: "uom",
            sortable: true,
        },
    ];

    const data = [
        {
            siteId: "SIT001",
            contractorId: "",
            workId: "",
            workType: "",
            workDescription: "",
            workStartDate: "",
            workEndDate: "",
            taskId: "",
            taskDescription: "",
            taskPredecessors: "",
            taskSuccessors: "",
            requiredSkill: "",
            manpowerRequired: "",
            materialCategory: "",
            materialId: "",
            materialName: "",
            materialDescription: "",
            quantity: "",
            uom: "",
        }

    ];

    const columnsPO = [
        {
            name: 'PRODUCT ID',
            selector: "productionId",
            sortable: true
        },
        {
            name: 'CUSTOMER ID',
            selector: "customerId",
            sortable: true
        },
        {
            name: 'SUPPLIER SITE',
            selector: "supplierSite",
            sortable: true
        },
        {
            name: 'CREATED BY',
            selector: "createdBy",
            sortable: true
        },
        {
            name: 'CREATED DATE',
            selector: "creationDate",
            sortable: true
        },
        {
            name: 'STATUS',
            selector: "status",
            sortable: true
        }
    ];

    const dataPO = [
        {
            id: 1,
            productionId: 'IT003',
            customerId: '20',
            supplierSite: 'SITE001',
            createdBy: 'XUA',
            creationDate: "25-11-2023",
            status: "Pending",
        },
        {
            id: 2,
            productionId: 'IT002',
            customerId: '20',
            supplierSite: 'SITE001',
            createdBy: 'XUA',
            creationDate: "25-11-2023",
            status: "Confirm",
        },
        {
            id: 3,
            productionId: 'IT001',
            customerId: '20',
            supplierSite: 'SITE001',
            createdBy: 'XUA',
            creationDate: "25-11-2023",
            status: "Confirm",
        },
        {
            id: 4,
            productionId: 'IT004',
            customerId: '20',
            supplierSite: 'SITE001',
            createdBy: 'XUA',
            creationDate: "25-11-2023",
            status: "Reject",
        },
    ]

    const columnsSPR = [
        {
            name: 'ORDER NUMBER',
            selector: "orderNumber",
            sortable: true
        },
        {
            name: 'SUPPLIER ID',
            selector: "supplierId",
            sortable: true
        },
        {
            name: 'PRODUCTION SCHEDULE ID',
            selector: "productionScheduleId",
            sortable: true
        },
        {
            name: 'PRODUCTION START DATE',
            selector: "productionStartDate",
            sortable: true
        },
        {
            name: 'PRODUCTION END DATE',
            selector: "productionEndDate",
            sortable: true
        },
        {
            name: 'CURRENT STATUS',
            selector: "currentStatus",
            sortable: true
        }
    ];

    const dataSPR = [
        {
            orderNumber: "ORD001",
            supplierId: "",
            productionScheduleId: "",
            productionStartDate: "",
            productionEndDate: "",
            currentStatus: "",
        }

    ]

    return (
        <div className="farmMaster">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h2 class="m-0 text-dark">Reports</h2>
                </div>
            </div>
            <div className="card">
                <div class="card-body">
                    <Tabs activeHeaderStyle={{ background: 'transparent' }}>
                        <Tab label="Planning Report" eventKey="Planning">
                            <div className='row'>
                                <div className='col-md-12'>
                                    <DataTable
                                        columns={columns}
                                        data={data}
                                        defaultSortField="batchID"
                                        pagination
                                        //selectableRows
                                        // selectableRowsComponent={BootyCheckbox}
                                        // onSelectedRowsChange = {handleChange}
                                        dense
                                        selectableRowsHighlight='true'
                                        compact
                                        highlightOnHover='true'
                                        striped
                                    />
                                </div>
                            </div>

                        </Tab>
                        <Tab label="Purchase Order Report" eventKey="Purchase Order">
                            <div className='row'>
                                <div className='col-md-12'>
                                    <DataTable
                                        columns={columnsPO}
                                        data={dataPO}
                                        defaultSortField="batchID"
                                        pagination
                                        //selectableRows
                                        // selectableRowsComponent={BootyCheckbox}
                                        // onSelectedRowsChange = {handleChange}
                                        dense
                                        selectableRowsHighlight='true'
                                        compact
                                        highlightOnHover='true'
                                        striped
                                    />
                                </div>
                            </div>
                        </Tab>
                        <Tab label="Supplier Production Report" eventKey="Supplier Production">
                            <div className='row'>
                                <div className='col-md-12'>
                                    <DataTable
                                        columns={columnsSPR}
                                        data={dataSPR}
                                        defaultSortField="batchID"
                                        pagination
                                        //selectableRows
                                        // selectableRowsComponent={BootyCheckbox}
                                        // onSelectedRowsChange = {handleChange}
                                        dense
                                        selectableRowsHighlight='true'
                                        compact
                                        highlightOnHover='true'
                                        striped
                                    />
                                </div>
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </div>
    )
}

export default Reports;